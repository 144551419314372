import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['project'];

  //Event handlers
  filtersUpdatedHandler = (event) => this.updateCostTable()

  connect(){
    window.addEventListener('filters-updated', this.filtersUpdatedHandler)
  }

  disconnect(){
    window.removeEventListener('filters-updated', this.filtersUpdatedHandler)
  }

  toggleProjects(){
    const companyId = event.currentTarget.dataset.id;

    const projects = this.projectTargets.filter(project => project.dataset.companyId === companyId)

    projects.forEach(project => project.classList.toggle('hidden'))

    event.currentTarget.querySelector('i').classList.toggle('fa-chevron-down')
    event.currentTarget.querySelector('i').classList.toggle('fa-chevron-up')
  }

  updateCostTable(){
    const url = '/costs/update_panel'
    const body = JSON.parse(document.querySelector('[data-controller="filter"]').dataset.parameters)

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      this.element.insertAdjacentHTML('afterend', data.panel)
      this.element.remove();
    });
  }
}