
import "@hotwired/turbo-rails"
import "./controllers"
import MicroModal from 'micromodal'

//icons
import "@fortawesome/fontawesome-pro/css/fontawesome.css"
import "@fortawesome/fontawesome-pro/css/light.css"
import "@fortawesome/fontawesome-pro/css/solid.css"

// Import js components
import initializeTooltips from './components/app_tooltips';

document.addEventListener("turbo:load", () => {
  MicroModal.init();

  // Initialize tooltips
  initializeTooltips(document)
})