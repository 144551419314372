import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  updateRole(){
    const checkbox = event.currentTarget;
    const checked = checkbox.checked;
    const userId = checkbox.dataset.userId;

    const url = `/settings/users/${userId}`;

    const body = { admin: checked }

    // AJAX call to update the user role
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'PATCH',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      window.location.reload()
    })
  }
}
