import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['copyLabel', 'list'];

  copy(){
    const copiedBtn = event.currentTarget
    // Copy the text inside the text field
    navigator.clipboard.writeText(this.listTarget.innerText.trim());

    // Initial text
    const initialText = this.copyLabelTarget.innerText
    const copiedText = this.copyLabelTarget.dataset.copied

    this.copyLabelTarget.innerHTML = copiedText
    copiedBtn.classList.remove('display-btn')
    copiedBtn.classList.add('display-btn-selected', 'pointer-events-none')
    setTimeout(() => {
      this.copyLabelTarget.innerHTML = initialText
      copiedBtn.classList.add('display-btn')
      copiedBtn.classList.remove('display-btn-selected', 'pointer-events-none')
    }, 500)
  }
}