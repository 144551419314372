import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['project'];

  //Event handlers
  onlyActiveToggledHandler = (event) => this.updateDataEvolutionTable(event.detail.active_only)

  connect(){
    window.addEventListener('only-active-toggled', this.onlyActiveToggledHandler)
  }

  disconnect(){
    window.removeEventListener('only-active-toggled', this.onlyActiveToggledHandler)
  }

  toggleProjects(){
    const companyId = event.currentTarget.dataset.id;

    const projects = this.projectTargets.filter(project => project.dataset.companyId === companyId)

    projects.forEach(project => project.classList.toggle('hidden'))

    event.currentTarget.querySelector('i').classList.toggle('fa-chevron-down')
    event.currentTarget.querySelector('i').classList.toggle('fa-chevron-up')
  }

  updateDataEvolutionTable(activeOnly){
    const url = '/data_evolutions/update_panel'
    const body = { only_active: activeOnly }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      this.element.insertAdjacentHTML('afterend', data.panel)
      this.element.remove();
    });
  }
}