import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';

export default class extends Controller {
  connect(){
    const dataset = JSON.parse(this.element.dataset.chart)

    const data = {
      labels: dataset.x,
      datasets: [{
        data: dataset.y,
        backgroundColor: '#26BD85',
        borderColor: '#1F9C6C',
        borderWidth: 1
      }]
    };

    const config = {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true
          },
          x: { display: true, type: 'time', time: { unit: 'week' } },
        },
        plugins: {
          legend: {
            display: false
          }
        }
      },
    };


    new Chart( this.element, config)

    // const data = JSON.parse(this.element.dataset.chartData);

    // var plotlyData = [
    //   {
    //     x: data['x'],
    //     y: data['y'],
    //     type: 'bar',
    //     color: '#3797d2',
    //   }
    // ];

    // var layout = {
    //   margin: {
    //     t: '0',
    //     r: '0',
    //     pad: '10'
    //   },
    //   xaxis: {
    //     color: '#7f7f7f',
    //     gridcolor: '#d8d8d8',
    //     automargin: true
    //   },
    //   yaxis: {
    //     title: { 'text': data['yaxis'] },
    //     color: '#7f7f7f',
    //     gridcolor: '#d8d8d8',
    //     rangemode: 'tozero',
    //     automargin: true
    //   },
    //   showlegend: false,
    //   hovermode: 'closest',
    //   clickmode: 'event+select',
    //   transition: {
    //     duration: '1000',
    //     easing: 'cubic-in-out'
    //   },
    //   paper_bgcolor: 'rgba(0,0,0,0)',
    //   plot_bgcolor: 'rgba(0,0,0,0)'
    // }


    // Plotly.newPlot(this.element, plotlyData, layout, {displayModeBar: false, responsive: true});
  }
}