import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['project'];

  toggleProjects(){
    const companyId = event.currentTarget.dataset.id;

    const projects = this.projectTargets.filter(project => project.dataset.companyId === companyId)

    projects.forEach(project => project.classList.toggle('hidden'))

    event.currentTarget.querySelector('i').classList.toggle('fa-chevron-down')
    event.currentTarget.querySelector('i').classList.toggle('fa-chevron-up')
  }
}