// app/javascript/controllers/confirm_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  confirm(event) {
    // Le message de confirmation
    const message = this.element.dataset.confirm;
    
    // Affiche la boîte de dialogue de confirmation
    if (!confirm(message)) {
      event.preventDefault();  // Empêche l'exécution de l'action si l'utilisateur clique sur "Annuler"
    }
  }
}