import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [];

  toggleClosedCustomer(){
    const customEvent = new CustomEvent("only-active-toggled", { detail: { active_only: event.currentTarget.checked } });
    window.dispatchEvent(customEvent);
  }

  changeCustomer(){
    const customEvent = new CustomEvent("company-changed", { detail: { company: event.currentTarget.value } });
    window.dispatchEvent(customEvent);
  }

  changeBillingMonth(){
    const parameters = JSON.parse(this.element.dataset.parameters)
    parameters['billing_month'] = event.currentTarget.value
    this.element.dataset.parameters = JSON.stringify(parameters)
    const customEvent = new CustomEvent("filters-updated");
    window.dispatchEvent(customEvent);
  }

  changeExchangeRate(){
    const parameters = JSON.parse(this.element.dataset.parameters)
    parameters['exchange_rate'] = event.currentTarget.value
    this.element.dataset.parameters = JSON.stringify(parameters)
    const customEvent = new CustomEvent("filters-updated");
    window.dispatchEvent(customEvent);
  }
}
