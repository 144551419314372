import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  updateAction(){
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;

    const url = `/settings/actions/${this.element.dataset.id}`

    let body = {updates: {}}

    if (event.currentTarget.type === 'checkbox'){
      body['updates'][event.currentTarget.name] = event.currentTarget.checked;
    } else {
      body['updates'][event.currentTarget.name] = event.currentTarget.value;
    }

    fetch(url, {
        method: 'PATCH',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      if (data['success']){
        clearTimeout(this.timeout)
        const message = document.querySelector('[data-message-ok]')
        message.innerHTML = ''
        message.insertAdjacentHTML('beforeend', data['message'])
        message.classList.remove('hidden')
        this.timeout = setTimeout(() => {
          message.classList.add('hidden')
        }, 5000)
      }
    });
  }
}